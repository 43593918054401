<template>
  <div class="container">

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #name="{ row }">
        <div>
            {{row.mechanism.mechanism_name}}
        </div>
      </template>
      <template #handle="{ row }">
        <el-button size="small" type="success" @click="openUpdate(row)"
          >设置分成</el-button
        >
        <el-button size="small" type="primary" @click="openAmount(row)"
          >设置投入资金</el-button
        >
         <el-button size="small" type="danger" @click="unbind(row)"
          >解绑</el-button
        >
      </template>
    </auto-table>
  

   <el-button type="primary" @click="newBind">绑定新机构</el-button>
    <div v-loading="loading">
        <el-form v-if="formShow">
            <el-form-item label="搜索机构" v-if="mode == 'add'">
                <mechanism-select :condition="{is_union:false}" @change="changeMec"></mechanism-select>
                
            </el-form-item>
                <div class="mechanism" v-if="mechanism.id">
                    <img class="m-logo" :src="mechanism.mechanism_logo">
                    <div class="m-info">
                        <div class="m-title">
                            {{mechanism.mechanism_name}}
                        </div>
                        <div class="m-add">
                            {{mechanism.categories_child}}
                        </div>
                        <div class="m-add">
                            {{mechanism.mechanism_addr}}
                        </div>
                    </div>
                </div>
            <el-form-item :label="ratioTypeMap[r.type]" v-for="(r,i) in ratios" :key="i">
                <div class="flex" style="width:200px">
                    <el-input @blur="blurInput"  v-model.number="r.ratio" placeholder="比例,如0.5" type="number" :max="1" :min="0"></el-input>
                    <el-button type="primary" @click="updateRatioInfo(r)">更新</el-button>
                </div>
            </el-form-item>
            <el-form-item v-if="mode == 'add'">
                <el-button type="primary" @click="addBind">绑定</el-button>
            </el-form-item>
        </el-form>
    </div>
    
    <el-dialog
      title="设置投资金额"
      :visible.sync="amountShow"
      width="30%"
   >
       <el-form>
            <el-form-item label="合作方投资金额">
                  <el-input v-model.number="investForm.coop_amount" placeholder="开店投资金额" type="number" />元
            </el-form-item>
            <el-form-item label="海马运动投资金额">
                  <el-input v-model.number="investForm.self_amount" placeholder="开店投资金额" type="number" />元
            </el-form-item>
       </el-form>

    
      <span slot="footer" class="dialog-footer">
        <el-button @click="amountShow = false">取 消</el-button>
        <el-button type="primary" @click="updateInitAmount">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>
import {ratioTypeMap} from './components/type.js'

export default {
 
  data() {
    return {
      amountShow:false,
      investForm:{
          coop_amount: '',
          self_amount: ''
      },
      ratioTypeMap:ratioTypeMap,
      mechanism:{},
      form:{},
      coop_id:'',
      DataList: [],
      ratios:[],
      Option: [
        { name: "机构名称", value: "name", type: "custom" },
         { name: "投资金额", value: "mechanism.init_amount" },
        { name: "操作", value: "handle", type: "custom", width: "300px" },
      ],
      Total: 0,
      formShow:false,
      loading:false,
      current:{},
      mode: 'add',
      row:{}
    };
  },

  mounted() {
    this.coop_id = this.$route.query.id
    this.getList(1);
  },

  methods: {
    updateInitAmount(){
        if(this.investForm.id){
          let data = {
            id: this.investForm.id,
            mechanism_id: this.row.mechanism_id,
            coop_amount: this.investForm.coop_amount,
            self_amount: this.investForm.self_amount
          }
          this.$post('/user/mechanismInvest/update',data).then(res=>{
            this.$message('更新成功')
              this.amountShow = false
                this.row = {}
              this.investForm = {}
          })
        }else{
          let data = {
            mechanism_id: this.row.mechanism_id,
            coop_amount: this.investForm.coop_amount,
            self_amount: this.investForm.self_amount
          }
          this.$post('/user/mechanismInvest/insert',data).then(res=>{
            this.$message('更新成功')
              this.amountShow = false
              this.row = {}
              this.investForm = {}
          })
        }


        
    },
    //投资金额弹窗
    openAmount(row){
       this.row = row
       this.$get('/user/mechanismInvest/queryByMec',{mechanism_id:row.mechanism_id}).then(res=>{
          this.investForm = res.data.data || {}
           this.amountShow = true
        })
        
    },
    blurInput(v){
        let value = v.target.value
        if(value >= 1){
            this.$message('分成比例不能大于等于1')
        }
        if(value < 0){
            this.$message('分成比例不能小于0')
        }
    },
    //绑定
    newBind(){
        this.formShow = true
        this.mechanism = {}
        this.current = {}
        this.mode = 'add'
        this.ratios = [
            {type:1,ratio:'0.4'},
            {type:2,ratio:'0.3'}
        ]
    },
    changeMec(v){
        this.mechanism = v
    },
    unbind(row){
        this.$confirm('确定要解绑门店吗，解绑后将不再获得门店分成').then(()=>{
            let data = {
                id: row.id,
                mechanism_id: row.mechanism.id,
            }
            this.$post('/user/coopMechanismRelative/unbind',data).then(res=>{
                this.getList(1);
            })
        })
    },
    openUpdate(row){
        if(this.loading){
            return
        }
        this.mode = 'update'
        this.current = row
        this.loading = true
        this.mechanism = row.mechanism
        this.getMecRatios()
    },
    getMecRatios(){
        let data = {
            mechanism_id: this.mechanism.id,
        }
        this.$get('/user/unionMechanismRatio/query',data).then(res=>{
            this.ratios = res.data.data
            this.loading = false
            this.formShow = true
        })
    },
    updateRatioInfo(row){
        let value = row.ratio
        if(value >= 1){
            this.$message('分成比例不能大于等于1')
            return
        }
        if(value < 0){
            this.$message('分成比例不能小于0')
            return
        }
        let data = {
           id: row.id,
           ratio: value
        }
        this.$post('/user/unionMechanismRatio/update',data).then(res=>{
           this.$message('更新成功')
        })
    },
    addBind(){
      if(!this.mechanism.id){
        this.$message('请选择绑定机构')
        retutn
      }
      this.$axios({
        url: "/user/coopMechanismRelative/insert",
        data: {
          coop_id: this.coop_id,
          mechanism_id: this.mechanism.id,
          ratios: this.ratios
        },
        method:"post"
      }).then((res) => {
        this.formShow = false
        this.mechanism = {}
        this.ratios = []
       this.getList(1)
      });
    },
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/coopMechanismRelative/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          coop_id: this.coop_id
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>

.mechanism{
    display: flex;
    padding: 20px;
    background-color: #eee;
    width: fit-content;
    border-radius: 10px;
    margin: 20px 0;
}
.m-logo{
    width: 80px;
    height: 80px;
}
.m-info{
    margin-left: 10px;
    line-height: 1.5;
}
.m-title{
    font-size: 16px;
    font-weight: 600;
}
.m-add{
    font-size: 14px;
}
</style>