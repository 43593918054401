
  export const typeList = [
    {
      value: 'cooperator',
      label: "合作商",
    },
    {
      value: 'property',
      label: "物业",
    },
    {
      value: 'commerce',
      label: "商会",
    },
    {
        value: 'union',
        label: "联营",
      }
  ]


  export const coopTypeList = [
    {
      value: 'cooperator',
      label: "合作商",
    },
    {
      value: 'property',
      label: "物业",
    },
    {
      value: 'commerce',
      label: "商会",
    }
  ]

  export const ratioTypeMap = {
    1:'收益分成',
    2:'开店基金'
  }


  export const accountRecordTypeMap = {
    1:'场馆费用',
    2:'提现',
    3:'课时费',
    4:'开店基金入账',
    5:'开店基金扣除'
  }